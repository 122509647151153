body {
    margin: 0;
    padding: 0;
  }
  
  #fullPageImage {
    height: 100vh;
    width: 100%;
    background: url('./assets/choir_pic.jpeg') center/cover no-repeat; /* Replace with the actual path to your full-page image */
  }
  
  #department {
    color: blue;
  }
  
  /* Rest of your CSS styles */
  