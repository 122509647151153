@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@400;500;700&family=Lato:wght@400;700&display=swap');

/*<input type = "email" placeholder="Your E-mail address">*/

*{
    box-sizing: border-box;
    padding: 0px;
    margin:0px;
    font-family: 'Anek Latin', sans-serif;
}

html{
    scroll-behavior: smooth;
}

.support{
}

.container{
    padding: 56px 0;
    display: flex;
    justify-content: center;
    height: 100%;
}

.row{
    width: 100%;
    margin: 0 auto; 
}

nav{
    height: 110px;
    background-color: rgb(20, 20, 75);
    display: block;

}

.nav__container{
    width: 100%;
    display: flex;
    height: 110px;
    /*max-width: 1600px;*/
    /* 0 top and bottom 24px left and right*/
    padding: 0 24px;
    justify-content: space-between;
    margin: 0 auto;
    background-color: rgb(20, 20, 75);
    position: fixed;
    z-index: 100;
}

.nav__title{
    display: flex;
}

.h1__title{
    font-size: 40px;
    margin-top: 16px;
    color: white;
}
h1{
    font-size: 100px;
    color: rgb(20, 20, 75)
}

h7{
    color: rgb(20, 20, 75)
}

.nav .logo__wrapper{
    max-width: 60px;
    display: flex;
    align-items: center;
}

.nav__button--img{
    border: none;
}

@media(max-width:375px){
    .logo__wrapper{
        max-width: 45px;
    }
}

.luminis{
    max-width: 80px;
    border-radius: 55%;
    margin: 15px 0;
    position: absolute;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-name: fadeIn;
}

.img__title{
    height: 60px;
    width: 60px;
    margin: 8px 24px 0px 0px;
    border: none;
}

.nav__links--login{
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav__links--wrapper{
    display: flex;
    align-items: center;
    opacity: 0;
    position: absolute;
    right: 0;
    margin-right: 24px;
    display: flex;
    align-items: center;
    margin-top: 34px;
}

.link__hover--effect:hover{
    color: white;
    transition: all 1000ms;
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

li{
    font-weight: bold;
}

.nav__home--wrapper{
    display: flex;
    align-items: center;
}

@media(max-width:720px){
    .nav__home--wrapper{
        opacity: 0
    }
    .nav__links--wrapper{
        opacity: 1;
    }
}

.nav__home{
    display: flex;
    text-align: center;

}

.home__link{
    padding: 0px 0 0 18px;
}



.social__link--home{
    padding: 0 0 0 24px;
    display: flex;
}

.nav__bar{
    display: flex;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-name: fadeIn;
}

/* sidebar Nav */

@keyframes slideInLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.nav {
    position: relative;
    z-index: 10;
      
  }

  .social__link{
    display: flex;
  }

  .social{
    padding:10px 0;
  }

  .socialfacebook{
    padding:10px 10px 0 0px;
  }

  .nav button {
    position: fixed;
    left: 40px;
    top: 40px;
    z-index: 10;
    cursor: pointer;
    border: none;
    background: transparent;

  }

  .nav__links {
    overflow-y: scroll;
    list-style: none;
    position: fixed;
    top: 0;
    background: black;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 0;
    overflow: hidden;
    max-width: 290px;
    z-index: 10;
    
    
  }
  
  .nav__links.showMenu {
    width: 100%;
  }
  
  a {
    display: block;
    text-decoration: none;
    color: #bed2d5;
    text-transform: uppercase;
    font-weight: bold;
    
  }

  .nav__links li:first-child {
    margin-top: 6.5rem;
  }

  .nav__link{
    padding: 10px 40px;
  }

@media(max-width: 930px){
    .nav__link{
        font-weight:bold;
        font-size: 20px;
    }
    .h1__title{
        font-size: 30px;
        margin-top: 26px;
        color: white;
    }

    .header3{
        font-size: 45px;
        margin-bottom: 24px;
        text-align: center;
        font-family:cursive;
    }

    .para__luminis{
        font-size: 30px;
    }

    .about__title{
        font-size: 50px;
    }
}
/*
@media(max-width: 500px) {

    .nav__link{
        max-width: 250px;
        margin: 0px;
        padding-right: 12px;
        font-size: 20px;
    }

    .library, .contact_nav, .about{
        display: flex;
        justify-content: end;
        align-items: center;
    }

  
}
*/
/*
@media(max-width: 375px) {

    .nav__link{
        font-size: 20px;
    }
}
*/
/*
@media(max-width: 350px) {

    .nav__link{
        font-size: 15px;
    }
}
*/
/*
@media(max-width: 715px){

    .home,
    .department{
        display: none;
    }
    .about__subtitle{
        font-size: 40px;
    }

    .about__para__about{
        font-size: 14px;
    }
}
*/

@media(max-width: 800px){

    .img__small--section{
        /*make sure the logos go into rows of 2 when screen shrinks*/
        width:calc(100%/2);
    }
}

/*

HEADER

*/
#header{
    height: calc(100vh - 110px);
    background-image: url('./assets/Lum8-final.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position:relative;
}

@media(max-width: 600px){
    #header{
        object-fit: cover;
    }
}

.header__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    max-width: 1600px;
    height:100%;
}

.header__description{
    color:white;
    width:100%;
}

img{
    width: 100%;
}

.header__img--wrapper{
    width: 50%;
}

.header__img--wrapper:hover .header__img{
    filter: brightness(20%);
}

.header__title{
    text-align: center;
    padding: 0px;
    font-size: 65px;
    display: flex;
    justify-content: center;
}

.header__titles{
    display: flex;
    justify-content: center;
    align-items: center;
}

h2{
    font-size: 70px;
}

h3{
    font-size: 4vw;
    margin-left: 24px;
    margin-bottom: 30px;
}

h4{
    font-size: 6vw;
    margin-bottom: 36px;
    display: flex;
    justify-content:center;
    color: white;
}

h5{
    font-size: 40px;
    display: flex;
    justify-content: center;
    margin-right: 24px;
    margin-left: 24px;
}

h6{
    font-size: 36px;
    margin:24px;
}

.h7{
    font-size: 35px;
}

.header3{
    font-size: 45px;
    font-family:cursive;
}

#h9{
    font-size: 60px;
}

.header__title2{
    font-size: 45px;
    display: flex;
    justify-content: center;
    font-family: 'cursive';
}

.header__para{
    font-size: 46px;
    margin-bottom: 34px;
    text-align: center;
}

input{
    font-size: 24px;
    border-radius: 20px;
}

.btn{
    color: white;
    font-size: 36px;
    font-weight: bold;
    border-radius: 20px;
    border-style: none;
    padding: 12px 12px;
    background-color: rgb(246, 121, 3);
    position: absolute;
    bottom: 5%;
    
    
}

.btn__wrapper{
    right: 0%;
    margin-right: 120px;
}

.btn:hover{
    transform: scale(1.1)
}

.btn:hover{
    cursor: pointer;
    transition: all 1000ms;
}

.header__btn{
    text-align: center;
    display: flex;
    justify-content: center;
}

.header__links{
   text-align: center;
}

.header__link{
    font-size: 90px;
    color: white;
    text-decoration: none;
}
/* ABOUT SECTION */

#about {
    background: linear-gradient(to bottom, #4e6cef, #1b1b5e); /* Original background gradient */
    color: #fff;
    padding: 60px 0;
    text-align: center;
}

.about__title {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #f0f0f0;
    letter-spacing: 1px;
}

.about__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.section__description {
    width: 90%;
    max-width: 1000px;
    background-color: #ffffff; /* Lightened box color */
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-bottom: 30px;
    color: #333; /* Darker text color for better readability */
    transition: all 0.3s ease-in-out;
}

.section__description:hover {
    transform: translateY(-5px);
}

.about__subtitle {
    font-size: 28px;
    font-weight: 600;
    color: #4e6cef; /* A subtle blue to match the theme */
    margin-bottom: 20px;
}

.about__para__about {
    font-size: 18px;
    line-height: 1.8;
    color: #555; /* Darker color for better contrast */
    padding-bottom: 10px;
    text-align: justify;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    .section__description {
        width: 100%;
        padding: 20px;
    }

    .about__title {
        font-size: 32px;
    }

    .about__subtitle {
        font-size: 24px;
    }

    .about__para__about {
        font-size: 16px;
    }
}

/* 

GALLERY

*/

#gallery{
    background-color: rgb(4, 19, 44);
    background-image: linear-gradient(rgb(19, 54, 159), rgb(4, 19, 44));
    background-image : linear-gradient(to bottom, 
    rgb(19, 54, 159), 
                    rgba(255,255,255, 1) 90%);

}

.flip-left{
    color: black;
}

.img__collection{
    width:80%;
    margin:100px auto 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
}

.img__collection img{
    width: 100%;
    height: 100%;
    cursor: pointer
}

.img__collection img:hover{
    transform: scale(1.1);
    border-radius: 20px;
    transition:all 1000ms;
    box-shadow: 0 32px 55px white;
}

.full-img {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 1001; /* Adjust the z-index to be higher than your navigation bar */
  }
  
  .full-img img {
    max-width: 80%;
    max-height: 80%;
    border-radius: 5px;
  }
  
  .full-img span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px; /* Increase the font size as needed */
    color: #fff;
    cursor: pointer;
}

  
  .full-img.open {
    display: flex;
  }
  
  
/*
LIBRARY
*/
/*
@media(max-width:1000px){
    .login__required{
        margin: 74px 0 74px 0;
    }
}
*/



/*
.library__popup--wrapper{
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.library__popup{
    font-size: 32px;
    opacity: 0;
    transition:all 1000ms;
}

.library__popup--wrapper:hover .library__popup{
    opacity: 1;
}

.library__popup--wrapper:hover .library__link{
    filter: brightness(200%);
    transform: scale(1.07);
}
*/

/*FOOTER*/

#footer{
    border-top-color: white;
    border-top: solid 0.2em;
}

.footer__sections{
    
    background-color: rgb(35, 36, 39);
    display: flex;
    justify-content:center;
}

.footer__section{
    display: flex;
    text-align: center;
    color: white;
    width: 100%;
    justify-content: center;
}

.footer__headers{
    font-size: 20px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.details{
    padding: 0 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.details__contact{
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact__details{
    color:rgb(201, 198, 108);
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.phone__footer{
    padding: 20px 0;
}

.choir__socialfacebook{
    padding-right: 14px;
}

.choir__socialinsta{
    padding-left: 14px;
}

@media(max-width:650px){
    .location, .location__postal--code, .phone{
        display: none;
    }

    .footer__section2{
        flex-direction: column;
    }
}

.maps{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.maps:hover{
    transform: scale(1.1);
    cursor:pointer; 
    transition: 1000ms;
}

.maps__img--wrapper{
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choir__links{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.choir__link{
    color: white;
    margin-left: 15px;
}

.details{
    font-size: 14px;
    text-decoration: none;
    margin-bottom: 5px;
}

.location__postal--code{
    margin-bottom: 14px;
}

.email{
    font-size: 30px;
    font-weight: bold;
    color:black;
    border-radius: 20px;
    background-color: rgb(246, 121, 3);
    border-style: none;
    padding: 10px 20px;
}

.head__color{
    color: white;
    text-decoration: none;
}

.head__color{
    text-decoration: none;
    background-color: rgb(4, 19, 44);
}