/* ChoirSection.css */

.choir-section {
    background-color: #f5f5f5;
    padding: 50px 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.choir-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.choir-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}

.choir-subtitle {
    font-size: 2.2rem;
    color: rgb(20, 20, 75);
    margin-bottom: 30px;
    font-style: italic;
}

.choir-content {
    font-size: 1rem;
    line-height: 1.8;
    color: #555;
}

.choir-description {
    margin-bottom: 20px;
}

.choir-highlight {
    font-weight: 700;
    color: #0073e6;
}

/* New link styling */
.choir-link {
    color: #0073e6;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease;
}

.choir-link:hover {
    color: #005bb5;
    text-decoration: underline;
}

/* Media Queries for smaller screens */
@media screen and (max-width: 768px) {
    .choir-container {
        padding: 15px;
    }

    .choir-title {
        font-size: 2rem;
    }

    .choir-subtitle {
        font-size: 1rem;
    }
}
