.season__concert--wrapper{
    display: flex;
    padding: 54px 54px 12px 54px;
    background: 
      linear-gradient(rgb(85, 85, 85) 0 0) bottom/ 60% 1px no-repeat;
}

.news__img{
    max-width: 350px;
}

.news__img--wrapper{
    padding: 22px;
}

.news__Describe{
    padding: 0 0 24px 0;
}

.concert__info{
    flex-direction: column;
    display: flex;
    width: 50%;
    padding: 22px;
}

.concert__date{
    font-family: dm sans;
    font-size: 22px;
    font-weight: 700;
}
.concert__location{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 300;
    padding: 22px 0;
}

.concert__name{
    font-family: dm sans;
    font-size: 34px;
    font-weight: 600;
    color: #e8ad46;
    padding: 0 0 22px 0;
}

.concert__singers{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
}

.concert__warning--wrapper{
    padding: 24px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-align: center;
}

@media (max-width:900px){
    .season__concert--wrapper{
        display: flex;
        flex-direction: column;
    }

    .concert__info{
        width: 100%;
        text-align: center;
    }

    .news__img--wrapper{
        display: flex;
        justify-content: center;
    }

    .news__img{
        width: 350px;
    }

    .news__button--wrapper1{
        padding: 20px 0;
        display: flex;
        justify-content: center;
    }
}

.news__button{
    max-width: 90px;
}

.news__button--wrapper1{
    padding: 20px 0;
    display: flex;
}

.news__info{
    background-color:rgb(40, 40, 40);
}