h1{
    font-size: 30px;
}
/*
<div class="testimonial">
		        <p>"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor, diam id ultrices faucibus, ex purus ullamcorper velit, ut tincidunt arcu nisl ut tortor. Sed iaculis massa nec imperdiet aliquet. Pellentesque euismod purus ac nibh tincidunt, et euismod enim aliquam."</p>
		        <span>- John Smith, happy client</span>
	        </div>

                background-image: url('./assets/churchnews.jpeg');

*/


/* Set font and background colors */
body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
}

/* Style the testimonials container */
.testimonials {
    margin: 0 auto;
    padding: 50px 0;
    max-width: 800px;
    text-align: center;
}

/* Style the testimonial section heading */
.testimonial h1 {
    text-align: center;
    color: #333;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 50px;
}

/* Style the individual testimonials */
.testimonial {
    margin-bottom: 50px;
    padding: 30px;
    background-color: rgb(215, 254, 254);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;    
    border: 1px solid #ccc;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.1);
}

/* Style the testimonial text */
.testimonial p {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
    color: black;
    text-align: justify;
}

/* Style the testimonial author */
.testimonial span {
    font-weight: bold;
    color: black(248, 248, 248);
    display: block;
    text-align: center;
    margin-top: 20px;
}

