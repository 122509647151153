.danil{
    background-size: cover;
    display: flex;
    background-image: url('assets/danil_background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.elena__img{
    max-width: 20vw;
    border-radius: 5%;
}

.about__para{
    font-size: 20px;
    line-height: 1.5;
    padding-bottom: 90px;
    /*border-bottom: 4px dashed black;*/
    margin-bottom: 10px;
    padding: 0 22px 22px 22px;
}

.elena__img--wrapper{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 70px;
    margin-left: 70px;
}

.elena__heading{
    display: flex;
    justify-content: center;
    margin: 36px 0;
}

.elena__info{
    margin: 50px 30px 40px 30px;
    background-color: lightgrey;
    border-style:groove;
    /*box-shadow: 0 10px 40px 0 blue;*/
}
/*
@media(max-width:600px){
    .about__para{
    }
}
*/
.elena__para{
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.3;
}

.lead__name{
    margin-top:24px;
}

@media (max-width:800px){
    .danil{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .elena__img--wrapper{
        margin: 0;
        display: flex;
        justify-content: center;
    }

    .about__para{
        font-size: 10px;
    }
}

@media (max-width:800px){
    .elena__info{
        padding: 0;
    }
}
