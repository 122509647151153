.dropdown-menu{
    padding: 48px 0 0 0;
    width: 200px;
    position:absolute;
    list-style:none;
    text-align:start;
}

.dropdown-menu li {
    background: rgb(24, 24, 27);
    cursor:pointer;
}

.dropdown-menu li:hover{
    background: #5cabff;
}

.dropdown-menu.clicked{
    display: none;
}

.dropdown-link{
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: aliceblue;
    padding: 16px;
}