h6{
    box-sizing: border-box;
    margin:0px;
}

.contact--wrapper{
    background-color: rgb(20, 20, 75);
    border: solid 0.2em;
    border-top-color: white;
} 

.contact{
    background-color: white;
    max-width: 100%;
    justify-content: space-around;    
    padding: 66px 24px 66px 24px;
    max-width: 900px;
    margin: 0 auto;
}

.contact__wrapper1{
    display: flex;
    justify-content: center;
}

@media (max-width: 790px){
    .contact__wrapper1{
        flex-direction: column;
    }

}

.maps__img{
    max-width: 40vw;
}

.map__img--wrapper{
    display: flex;
    align-items: center;
}

.address{
    line-height: 1.2;
    font-size: larger;
}

.bold{
    font-weight:500;
    line-height: 1.5;
}

.gold{
    padding-bottom: 12px;
    color: rgb(222, 195, 42);

}

.phone{
    font-size: larger;
    padding: 24px 0;
}

.contact__inquiry{
    padding: 56px 0;
}

.map__img--wrapper:hover{
    transform: scale(1.05);
    cursor:pointer; 
    transition: 5000ms;
}