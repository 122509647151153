.sponsors__wrapper{
    background-color: rgb(35, 36, 39);
}
.sponsors__title{
    font-family:'Verdana', 'Geneva', 'Tahoma', 'sans-serif';
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding: 35px 0 12px 0;
    color: white;
    text-align: center;
}

.sponsors__logo{
    display: flex;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
    padding: 24px;
}

.sponsor{
    max-width: 100px;
}

.sponsorthin{
    max-width: 200px;
}

.sponsor3{
    border-radius: 99px;
}

@media(max-width:500px){
    .sponsor{
        width: 60px;
    }
}